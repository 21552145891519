.mtfuji_wizard {
  padding-bottom: 40px;
}

.mtfuji-content-proj-sel h3 {
  padding-bottom: 20px;
}

.mtfuji_wizard .MuiAlert-message {
  font-size: inherit;
  font-weight: inherit;
}

.mtfuji_wizard code {
  padding: 0.2em 0.4em;
  margin: 0;
  font-size: 85%;
  background-color: #8f939942;
  border-radius: 6px;
}

.mtfuji_wizard .overview-item code {
  padding: 0.2em 0.4em;
  font-size: inherit;
  overflow-x: scroll;
  min-width: 100%;
  width: 0px;
  display: inline-block;
  white-space: normal;
}

.mtfuji_wizard code.snippet,
.mtfuji_wizard *[class^='language-'] {
  display: block;
  width: auto;
  overflow-x: auto;
  overflow-y: visible;
  padding: 12px;
}

.mtfuji_wizard .MuiCard-root {
  height: 100%;
}

.mtfuji_wizard .MuiCollapse-root .MuiCardContent-root {
  padding: 0 24px 24px;
}

.mtfuji_wizard .overview-item {
  padding-bottom: 0px;
}

.mtfuji_wizard .overview-item.last-item {
  padding-bottom: 24px;
}

.mtfuji_wizard .overview-item .overview-item-value {
  white-space: pre;
  overflow-wrap: break-word;
  word-break: keep-all;
  word-wrap: break-word;
}

.mtfuji_wizard .mtfuji-field-array-table tbody td {
  border-bottom: 0;
}

.mtfuji_wizard .mtfuji-field-array-table tbody td p {
  margin-top: 0;
}

.mtfuji_wizard .mtfuji-field-array-table tbody tr:nth-of-type(even) {
  background-color: #f7f7f7;
}

.mtfuji_wizard .mtfuji-field-array-table tbody tr td:not(:last-child) > div {
  margin-top: 10px;
}

.mtfuji_wizard .mtfuji-field-array-table tbody tr td:last-child > div {
  margin-top: -15px;
}

.mtfuji_wizard button.submit-button:hover {
  background-color: #0e4295;
}
